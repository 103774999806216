<template>
  <a class="HambuegerBtn" @click="ActionMenu(status)">
    <label class="bars">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </label>
  </a>
</template>

<script>
export default {
  props: ["action", "status"],
  methods: {
    ActionMenu: function(status) {
      if (this.action) {
        this.$emit("action");
      } else {
        this.UserAdminMenu(status);
      }
    },
  },
};
</script>

<style lang="scss">
.HambuegerBtn {
  display: flex;
  > * * {
    pointer-events: none;
  }
  .bars {
    width: calc(24px + #{$mpadding * 2});
    cursor: pointer;
    padding: $mpadding;
    display: block;
    .line {
      margin: 6px 0;
      background-color: #aeaeaf;
      width: 100%;
      height: 2px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: all 450ms ease-in;
      -moz-transition: all 450ms ease-in;
      -o-transition: all 450ms ease-in;
      transition: all 450ms ease-in;
      &:nth-child(3) {
        width: 60%;
        margin-left: 40%;
      }
    }
  }
  @media (min-width: $tablet_width) {
    /* display: none; */
  }
}
*.onmenu {
  .HambuegerBtn .line:nth-child(1) {
    -webkit-transform: translateY(410%) rotate(40deg);
    -ms-transform: translateY(410%) rotate(40deg);
    transform: translateY(410%) rotate(40deg);
  }
  .HambuegerBtn .line:nth-child(2) {
    opacity: 0;
  }
  .HambuegerBtn .line:nth-child(3) {
    -webkit-transform: translateY(-370%) rotate(138deg);
    -ms-transform: translateY(-370%) rotate(138deg);
    transform: translateY(-370%) rotate(138deg);
    width: 100%;
    margin-left: 0%;
  }
}
</style>
