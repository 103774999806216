<template>
  <div :class="`user-profile-edit`" v-if="user.user">
    <form id="user-profile-data" enctype="multipart/form-data" @change="error = null" @submit.prevent="">
      <div class="UserProfileEditData">
        <div class="user-profile-content">
          <div class="UserProfileData">
            <UserProfileEditCover :user="user" :readURL="readURL" :onchange="getData" />
            <div class="user-profile-card">
              <div class="user-edit-form">
                <Collapse
                  ref="Collapse1"
                  icon="mf_cog"
                  :title="`<strong>${locale['object']['user-profile']['profile-data-label']}</strong>`"
                  :settings="{ opened: redirect ? true : false }"
                >
                  <div class="collapse-block">
                    <div class="block-input">
                      <InputLabel :label="$locale['full_name']" :required="true" />
                      <input
                        type="text"
                        name="name"
                        :value="user.name"
                        :placeholder="locale['object']['user-profile']['input-name-placeholder']"
                        required
                        @blur="$validate.validateInput($event, { tooltip: false })"
                      />
                    </div>
                    <div :class="`block-input ${preventChangeEmail ? '__prevent' : ''}`">
                      <InputLabel :label="$locale['input-user-name']" :required="true" />
                      <input
                        type="text"
                        name="user"
                        :value="user.user"
                        :placeholder="locale['input-user-name-placeholder']"
                        required
                        @blur="$validate.validateInput($event, { tooltip: false })"
                        :disabled="preventChangeEmail"
                      />
                    </div>
                    <div class="block-input">
                      <InputLabel :label="$locale['description']" />
                      <TextareaRich
                        name="describe"
                        :content="user.describe"
                        :placeholder="locale['object']['user-profile']['user-input-describe-placeholder']"
                        :encode="true"
                        @paste.prevent="onPaste"
                      />
                    </div>
                    <div class="block-input">
                      <InputLabel :label="$locale['country']" :required="true" />
                      <a cursor="pointer" class="country-selector" :style="`--flag:url(${user.country_flag})`">
                        <input
                          v-country="{ cardStyle: { 'min-height': 'calc(100vh - calc(var(--header-height)*2))' }, onApply: countrySelected }"
                          readonly
                          type="text"
                          name="country"
                          :value="user.country"
                          :placeholder="locale['object']['user-profile']['user-input-country-placeholder']"
                          capitalize
                        />
                        <input type="hidden" name="country_flag" :value="user.country_flag" />
                      </a>
                    </div>
                    <div class="block-input" v-if="$isCreator">
                      <InputLabel :label="$locale['profile_url']" :required="true" />
                      <input
                        type="text"
                        name="website"
                        :value="getUserUrl(user.website)"
                        :placeholder="locale['object']['user-profile']['user-input-website-placeholder']"
                        readonly
                      />
                    </div>
                  </div>
                  <div dspacer />
                </Collapse>
                <Collapse
                  ref="Collapse2"
                  icon="mf_cog"
                  :title="`<strong>${locale['object']['user-profile']['personal-data-label']}</strong>`"
                  :settings="{ opened: redirect ? true : false }"
                >
                  <div class="collapse-block">
                    <div class="block-input">
                      <InputLabel :label="$locale['email']" :required="true" />
                      <input
                        type="email"
                        name="email"
                        :value="user.email"
                        :placeholder="locale['object']['user-profile']['input-email-placeholder']"
                        required
                        @blur="$validate.validateInput($event, { tooltip: false })"
                      />
                    </div>
                    <div class="block-input phone-block-input">
                      <InputLabel :label="$locale['phone']" />
                      <div :class="`phone-selector`" :style="`--flag:url(${user.calling_code ? user.calling_code_flag : null})`">
                        <span class="prefix" @click="callingCodeSelect">{{ user.calling_code }}</span>
                        <input type="hidden" name="calling_code" :value="user.calling_code" placeholder="00" />
                        <input type="hidden" name="calling_code_flag" :value="user.calling_code_flag" />
                        <input
                          type="tel"
                          name="phone"
                          :value="user.phone"
                          :placeholder="locale['object']['user-profile']['input-phone-placeholder']"
                        />
                      </div>
                      <div class="calling-code-select">
                        <UserCountryCodePhoneSelect v-if="callingCode" ref="CallingCodes" @code="codeSelected" />
                      </div>
                    </div>
                    <div class="block-input">
                      <InputLabel :label="$locale['birthday']" :required="true" />
                      <input
                        v-calendar="{ date: $global.toInputDate(user.birthday) }"
                        type="date"
                        name="birthday"
                        :value="$global.toInputDate(user.birthday)"
                        :placeholder="locale['object']['user-profile']['input-birthday-placeholder']"
                      />
                    </div>
                  </div>
                  <div dspacer />
                </Collapse>

                <UserProfileEditMisc class="UserProfileMiscEdit" :user="user" />

                <Collapse
                  class="subscriptions"
                  v-if="isCreator()"
                  ref="Subscriptions"
                  icon="mf_cog"
                  :title="`<strong>${locale['object']['user-profile']['section-subscriptions-title']}</strong>`"
                >
                  <div class="collapse-block">
                    <div class="block-input">
                      <div class="title">
                        <div class="title-left">
                          <span bold>{{ locale["object"]["user-profile"]["input-month-title"] }}</span>
                        </div>
                        <div class="title-right">
                          <ToggleSwitchLight name="subscriptions.priceByMonth.status" :value="user.subscriptions.priceByMonth.status" />
                        </div>
                      </div>
                      <label>
                        <span>{{ locale["montly_subscriptions_desc"] }}</span>
                        <span dcolor v-html="`&nbsp;${$locale['max']}&nbsp;`"></span>
                        <span>{{ $locale["subscription_max_price"] }}</span>
                      </label>

                      <InputFormat
                        type="number"
                        name="subscriptions.priceByMonth.mount"
                        :value="user.subscriptions.priceByMonth.mount"
                        :placeholder="locale['price']"
                        format="USD"
                      />
                    </div>
                    <div class="block-input" hide>
                      <div class="title">
                        <div class="title-left">
                          <span bold>{{ locale["object"]["user-profile"]["input-semester-title"] }}</span>
                        </div>
                        <div class="title-right">
                          <ToggleSwitchLight name="subscriptions.priceBySemester.status" :value="user.subscriptions.priceBySemester.status" />
                        </div>
                      </div>
                      <label>{{ locale["object"]["user-profile"]["input-price-semester"] }}</label>
                      <input
                        type="number"
                        name="subscriptions.priceBySemester.mount"
                        :value="user.subscriptions.priceBySemester.mount"
                        :placeholder="locale['object']['user-profile']['input-price-semester-placeholder']"
                        @blur="$validate.validateInput($event, { tooltip: false })"
                      />
                    </div>
                    <div class="block-input" hide>
                      <div class="title">
                        <div class="title-left">
                          <span bold>{{ locale["object"]["user-profile"]["input-annual-title"] }}</span>
                        </div>
                        <div class="title-right">
                          <ToggleSwitchLight name="subscriptions.priceByYear.status" :value="user.subscriptions.priceByYear.status" />
                        </div>
                      </div>
                      <label>{{ locale["object"]["user-profile"]["input-price-year"] }}</label>
                      <input
                        type="number"
                        name="subscriptions.priceByYear.mount"
                        :value="user.subscriptions.priceByYear.mount"
                        :placeholder="locale['object']['user-profile']['input-price-year-placeholder']"
                        @blur="$validate.validateInput($event, { tooltip: false })"
                      />
                    </div>
                    <div class="block-input SubscriptionDiscount" hidden hide>
                      <div class="title">
                        <div class="title-left">
                          <span bold>{{ locale["object"]["user-profile"]["input-discount-title"] }}</span>
                        </div>
                        <div class="title-right">
                          <ToggleSwitchLight name="subscriptions.priceDiscount.status" :value="user.subscriptions.priceDiscount.status" />
                        </div>
                      </div>
                      <div class="label">{{ locale["object"]["user-profile"]["input-discount-label"] }}</div>
                      <div class="couple">
                        <div class="couple-input">
                          <label bold>{{ locale["object"]["user-profile"]["input-discount-date-label"] }}</label>
                          <input
                            v-calendar="{ date: $global.toInputDate(user.subscriptions.priceDiscount.dateLimit) }"
                            type="date"
                            name="subscriptions.priceDiscount.dateLimit"
                            :value="$global.toInputDate(user.subscriptions.priceDiscount.dateLimit)"
                            :placeholder="locale['object']['user-profile']['input-birthday-placeholder']"
                          />
                        </div>
                        <div class="couple-input">
                          <label bold>{{ locale["object"]["user-profile"]["input-discount-value-label"] }}</label>
                          <InputPrefix
                            :value="user.subscriptions.priceDiscount.percent"
                            name="subscriptions.priceDiscount.percent"
                            prefix="%"
                            dir="rtl"
                            :limit="100"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div dspacer />
                </Collapse>
                <UserProfileEditSocial class="social" v-if="isCreator() || Go.includes($route.query.redirect, 'user/creator?apply')" :user="user" />
                <CreatorUserEdit class="creator" v-if="isCreator()" :user="user" />
                <UserAccountSettings class="UserProfileAccount" />
                <Spacer num="2" />
                <Confirm
                  :confirm="{ cancel: { label: $locale['come_back'], icon: 'arrowLeft' }, accept: { label: $locale['save_profile'], icon: 'check' } }"
                  @accept="sendData('user-profile-data')"
                  @cancel="goBack"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacer num="1" />
    </form>
  </div>
</template>

<script>
import ViewHeader from "../view/ViewHeader.vue";
import Collapse from "../widgets/Collapse.vue";
import TextareaRich from "../widgets/TextareaRich.vue";
import ToggleSwitchLight from "../inputs/ToggleSwitchLight.vue";
import InputPrefix from "../inputs/InputPrefix.vue";
import UserCountryCodePhoneSelect from "./UserCountryCodePhoneSelect.vue";
import UserProfileEditSocial from "./UserProfileEditSocial.vue";
import UserProfileEditMisc from "./UserProfileEditMisc.vue";
import CreatorUserEdit from "../creator/CreatorUserEdit.vue";
import UserAccountSettings from "./UserAccountSettings.vue";
import UserProfileEditCover from "./UserProfileEditCover.vue";
export default {
  components: {
    ViewHeader,
    Collapse,
    TextareaRich,
    ToggleSwitchLight,
    UserCountryCodePhoneSelect,
    InputPrefix,
    UserProfileEditSocial,
    UserProfileEditMisc,
    CreatorUserEdit,
    UserAccountSettings,
    UserProfileEditCover,
  },
  data: function() {
    return {
      locale: null,
      user: {},
      loading: null,
      callingCode: null,
      redirect: null,
      explicitAccept: null,
      previusRoute: null,
      error: null,
      axios: {
        timeout: 1000 * 60 * 30,
        headers: {
          "use-form": "false",
        },
      },
    };
  },
  methods: {
    elements: function(el) {
      const elmns = {
        form: document.querySelector("#user-profile-data"),
        custom: document.querySelector(el),
      };
      return elmns[el] || elmns["custom"];
    },
    getUserUrl: function() {
      const url = `${location.protocol}//${location.host}/${this.user.user}`;
      return url;
    },
    isCreator: function() {
      if (this.user?.roles?.includes("creator")) {
        return true;
      }
    },
    validImage: function(file) {
      const fileExts = /(\.(png|jpeg|jpg))$/i;
      const isValid = fileExts.test(file);
      return isValid;
    },
    callingCodeSelect: function() {
      const phoneBlock = document.querySelector(`#user-profile-data .phone-block-input`);
      phoneBlock.classList.toggle("on-select");
      if (phoneBlock.classList.contains("on-select")) {
        this.callingCode = true;
        setTimeout(() => {
          phoneBlock.scrollIntoView({ behavior: "smooth" });
        }, 50);
      } else {
        this.callingCode = null;
      }
    },
    codeSelected: function(data) {
      this.user.calling_code = data.callingCodes[0];
      this.user.calling_code_flag = data.flag;
      this.callingCodeSelect();
    },
    readURL: function(input) {
      if (!this.$isUserVerified) {
        this.$router.push("/user/verify");
        return;
      }
      if (!this.explicitAccept) {
        this.$store.state.user.profileExplicitContentAlert = true;
      }
      const files = input.target.files;
      const isValid = this.validImage(files[0].name);
      if (files && !isValid) {
        this.ialert.go({ message: this.locale["invalid-image-format"], unique: true, title: "Error" });
      } else if (files && files[0]) {
        let reader = new FileReader();
        reader.onload = function(e) {
          const fileData = e.target.result;
          let forElement = input.target.getAttribute("for");
          forElement = forElement && document.querySelector(forElement);
          forElement.style.backgroundImage = `url(${fileData})`;
        };
        reader.readAsDataURL(files[0]);
      } else {
        let forElement = input.target.getAttribute("for");
        let currentPic = forElement && document.querySelector(forElement).getAttribute("data-current-url");
        forElement = forElement && document.querySelector(forElement);
        forElement.style.backgroundImage = `url(${currentPic})`;
      }
    },
    isValidUser: function(formID) {
      const userName = this.elements(`#${formID} input[name="user"]`)?.value;
      return this.$global.validUserName(userName);
    },
    sendData: function(formID) {
      this.error = null;
      this.ialert.go({ type: "loader" });
      const _data = new FormData(document.getElementById(formID));
      const sedData = this.$api.post(process.env.VUE_APP_API_HOST + "user/update", _data, this.axios);
      sedData.then((response) => this.sendSuccess(response));
      sedData.catch((response) => this.sendError(response));
    },
    sendSuccess: async function({ data = {} } = {}) {
      if (data && data.success) {
        let [redirect] = [this.$route.query.redirect];
        await this.$store.dispatch("updateUser", data);
        this.ialert.close();
        this.showMessage({ icon: "success_animated", message: this.$locale["profile_updated"], type: "success", title: data.title });
        if (redirect) {
          this.$router.push(redirect);
        } else {
          this.goBack();
        }
        return;
      }
      this.ialert.close();
      this.showMessage({ icon: "ui_error", message: data.message, type: "error", title: data.title });
      this.error = data.message;
      if (data.field) {
        this.input = document.querySelector(`[name="${data.field}"]`);
        this.input && this.input.classList.add("data-error");
      }
    },
    sendError: function(response) {
      this.ialert.close();
      this.showMessage({ icon: "ui_error", message: response.toString(), type: "error", title: "Error" });
    },
    loadProfile: async function() {
      await this.$store.dispatch("getProfile", (profile) => {
        this.user = { ...this.$user, ...profile.user };
      });
    },
    getData: async function() {
      let [redirect] = [this.$route.query.redirect];
      await this.loadProfile();
      this.locale = this.$locale;
      this.redirect = redirect;
    },
    countrySelected: function(country) {
      if (country && country.name) {
        this.user.country = country.name;
        this.user.country_flag = country.flag;
        this.user.calling_code = country.callingCodes[0];
        this.user.calling_code_flag = country.flag;
      }
    },
  },
  mounted: function() {
    this.getData();
  },
  computed: {
    preventChangeEmail: function() {
      const ePrevent = !this.user.user.includes("@");
      return ePrevent;
    },
  },
};
</script>
<style lang="scss">
$avatar_size: 72px;
.user-profile {
  .main-view-header {
    .save-btn {
      margin: 0 $mpadding;
    }
  }

  .camera-figure {
    @include Flex(inherit, center, center);
    width: $mpadding * 2;
    height: $mpadding * 2;
    background: #0000008a;
    color: #fff;
    font-size: 100%;
    border-radius: 50%;
    z-index: 2;
    .iconic {
      @include Flex(inherit, center, center);
    }
  }

  .cover-input-area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    background-color: $primary_color;
  }

  .avatar-input-area {
    width: $avatar_size;
    height: $avatar_size;
    position: absolute;
    left: $mpadding * 1.5;
    bottom: -$avatar_size/2;
    z-index: 2;
    .picture {
      @include Flex(inherit, center, center);
      @include BgImage();
      width: $avatar_size;
      height: $avatar_size;
      border-radius: 50%;
      color: #fff;
      border: solid 2px #fff;
      background-image: var(--userAvatar);
      &.src {
        color: rgba(255, 255, 255, 0);
      }
    }
    .flag {
      @include avatarFlag();
      z-index: 1;
    }

    .user-avatar {
      border: solid 3px #fff;
    }
  }
  .user-edit-form {
    margin: $mpadding * 1.5 0 0 0;
    > .collapse .collapse-header {
      padding: $mpadding 0;
      border-radius: 0;
      transition: all 0.25s ease-in-out;
      border-bottom: solid 1px $alto;
      .title {
        opacity: 0.6;
        font-size: 90%;
      }
    }
    @include screen($tablet_width) {
      > .collapse:hover .collapse-header {
        background-color: $button_contrast;
        border-radius: $mpadding/2;
        border: none;
      }
    }
    > .collapse:last-child .collapse-header {
      border-bottom: 0;
    }
    > .collapse.opened .collapse-header {
      border-bottom: solid 1px $alto;
      * {
        color: $primary_color;
      }
      .title {
        opacity: 1;
      }
    }
    .collapse.subscriptions .collapse-content {
      padding: 0 $mpadding;
    }
    .country-selector,
    .phone-selector {
      position: relative;
      @include Flex(inherit, center, center);
      input[name="country"],
      input[name="phone"] {
        padding: 0 $mpadding 0 $mpadding * 3;
      }
      &:before {
        content: "";
        width: $mpadding * 2;
        height: $mpadding * 2;
        position: absolute;
        left: $mpadding/2;
        background-image: var(--flag);
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        background-color: $sec_color;
      }
      input[name="calling_code"] {
        position: absolute;
        left: 0;
        background: none;
        border: none;
        width: $mpadding * 6.3;
        height: $mpadding * 2;
        border-right: solid 1px $alto;
        border-radius: 0;
        padding: 0 0 0 $mpadding * 3.3;
        cursor: pointer;
      }
      input[name="phone"] {
        padding: 0 $mpadding 0 $mpadding/2;
        border-radius: 0 2pc 2pc 0;
        border: none;
      }
      .prefix {
        @include Flex(inherit, center, center);
        color: $sec_color;
        z-index: 1;
        padding: 0 $mpadding/2 0 $mpadding * 2.8;
        border-right: solid 1px $alto;
        cursor: pointer;
        height: $mpadding * 2;
        user-select: none;
        &:before {
          content: "+";
          margin: 0 $mpadding/3 0 0;
        }
        &:after {
          font-family: FontAwesome;
          content: "\f078";
          font-size: 80%;
          margin: 0 0 0 $mpadding/2;
        }
      }
    }
    .block-input {
      .calling-code-select {
        display: none;
      }
      .phone-selector {
        border: solid 1px $alto;
        border-radius: 2pc;
      }
      &.on-select {
        z-index: 1;
        .phone-selector {
          border-radius: $mpadding $mpadding 0 0;
          border-bottom: 0;
        }
        .calling-code-select {
          display: block;
          width: 100%;
          min-height: 50px;
          max-height: 200px;
          border: solid 1px $alto;
          border-radius: 0 0 $mpadding $mpadding;
          overflow-y: auto;
        }
        .prefix {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
      &.__prevent {
        input {
          background-color: $alto;
          pointer-events: none;
        }
      }
    }
    .couple {
      display: flex;
      justify-content: space-evenly;
      margin: $mpadding 0 0 0;
      &-input {
        &:first-child {
          margin: 0 $mpadding/2 0 0;
        }
        &:last-child {
          margin: 0 0 0 $mpadding/2;
        }
        label {
          text-align: left;
        }
      }
    }
    .subscriptions {
      .block-input {
        padding: $mpadding 0 0 0;
        .title {
          @include Flex(inherit, space-between, center);
          margin: 0 0 $mpadding 0;
          &-left,
          &-right {
            @include Flex(inherit, center, center);
            height: 100%;
          }
        }
        label,
        .label {
          text-align: center;
          font-size: 80%;
        }
        .couple label {
          text-align: left;
          font-size: 100%;
        }
        input,
        .couple-input input {
          text-align: center;
          font-size: 110%;
        }
        &:not(:first-child) {
          border-top: solid 1px $alto;
        }
      }
    }
  }
  .subscriptionPriceInput {
    .ToggleValuesSwitch {
      justify-content: center;
    }
  }
  @media (min-width: $tablet_width) {
    .cover-input-area {
      border-radius: $mpadding $mpadding 0 0;
    }
    .user-edit-form {
      .subscriptions {
        .block-input {
          label,
          .label {
            font-size: 100%;
          }
        }
      }
    }
  }
}
</style>
