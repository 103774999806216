<template>
  <div class="social-widget" v-if="user">
    <a class="primary-circle --instagram" v-if="user.instagram" :href="user.instagram" target="_blank">
      <iconic name="instagram" />
    </a>
    <a class="primary-circle --twitter" v-if="user.twitter" :href="user.twitter" target="_blank">
      <iconic name="twitter" />
    </a>
    <a class="primary-circle --tiktok" v-if="user.tiktok" :href="user.tiktok" target="_blank">
      <iconic name="tiktok" />
    </a>
    <a class="primary-circle --facebook" v-if="user.facebook" :href="user.facebook" target="_blank">
      <iconic name="facebook" />
    </a>
  </div>
</template>

<script>
export default {
  props: ["user"],
};
</script>

<style lang="scss" scoped>
.social-widget {
  display: flex;
  .primary-circle {
    color: #fff;
    font-size: 150%;
    box-shadow: inset 0 0 0 1px rgba($color: #000000, $alpha: 0);
    &:hover {
      box-shadow: inset 0 0 0 1px rgba($color: #000000, $alpha: 0);
    }
  }

  .--instagram {
    background: #f09433;
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }

  .--twitter {
    background: #00aced;
    background: linear-gradient(45deg, #00aced 0%, #00abf3 25%, #0096ee 50%, #008de2 75%, #0080e1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00aced', endColorstr='#0080e1',GradientType=1 );
  }

  .--facebook {
    background: #3b5998;
    background: linear-gradient(45deg, #3b5998 0%, #3b5998 25%, #3b5998 50%, #3b5998 75%, #3b5998 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b5998', endColorstr='#3b5998',GradientType=1 );
  }

  .--tiktok {
    background: #000000;
    background: linear-gradient(45deg, #000000 0%, #000000 25%, #000000 50%, #000000 75%, #000000 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=1 );
  }
}
</style>
