var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$profile)?_c('a',{staticClass:"user-credits-btn",on:{"click":function($event){_vm.view({
      header: false,
      class: 'clean menu',
      animation: 'topIn',
      closeOnSwipe: 'up',
      closeOutside: true,
      component: function () { return import('../credits/CreditsCard.vue'); },
    })}}},[_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm.$profile.balance))]),_c('iconic',{attrs:{"name":"coins"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }