<template>
  <div class="view-more relative" v-if="morepage">
    <a v-if="loading" inline-b><iconic name="gloading" /></a>
    <a v-else class="sec-button" inline-b dcolor @click="$emit('click')">{{ $locale["object"]["nav"]["view-more-btn"] }}</a>
  </div>
</template>

<script>
export default {
  props: ["label", "morepage", "loading"],
};
</script>

<style lang="scss" scoped>
.view-more {
  text-align: center;
}
</style>
