<template>
  <div class="textarea-rich">
    <div
      class="textarea-rich-box"
      textarea
      :placeholder="placeholder"
      contenteditable="true"
      v-html="content && decodeURIComponent(content)"
      :name="name"
      @input="update"
      @click="$emit('click', $event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @paste="$emit('paste', $event)"
    ></div>
    <input type="hidden" :name="name" :value="value" />
  </div>
</template>

<script>
export default {
  props: ["placeholder", "content", "name", "encode"],
  data: function() {
    return {
      value: this.content,
    };
  },
  methods: {
    update: function($event) {
      this.value = $event.target.innerHTML;

      if (this.encode) {
        this.value = encodeURIComponent(this.value);
      }

      this.$emit("input", $event);
      this.$emit("update", this.value);
    },
  },
};
</script>

<style lang="scss">
.textarea-rich {
  width: 100%;
  &-box {
    width: 100%;
    min-height: 70px;
    padding: $mpadding/2;
    border-radius: $mradius/2;
    border: solid 1px $alto;
    font-size: 100%;
    font-family: $primary_font;
    margin-top: 5px;
    line-height: normal;
    background: $white;
    cursor: text;
    &:empty::before {
      content: attr(placeholder);
      opacity: 0.75;
      pointer-events: none;
    }
  }
}
</style>
