<template>
  <div class="user-files">
    <div class="user-files-header">
      <a :class="`button ${tab === 'all' ? 'isActive' : ''}`" @click="goTab('all')">
        <i :class="`icon fa fa-${tab === 'all' ? 'bookmark' : 'bookmark-o'}`"></i>
        <span>{{ $locale["object"]["user-profile"]["alls-label"] }}</span>
      </a>
      <a :class="`button ${tab === 'photos' ? 'isActive' : ''}`" @click="goTab('photos')">
        <i :class="`icon fa fa-${tab === 'photos' ? 'bookmark' : 'bookmark-o'}`"></i>
        <span>{{ $locale["object"]["user-profile"]["photos-label"] }}</span>
      </a>
      <a :class="`button ${tab === 'videos' ? 'isActive' : ''}`" @click="goTab('videos')">
        <i :class="`icon fa fa-${tab === 'videos' ? 'bookmark' : 'bookmark-o'}`"></i>
        <span>{{ $locale["object"]["user-profile"]["videos-label"] }}</span>
      </a>
    </div>
    <div class="user-files-content">
      <UserFilesList ref="myFiles" :tab="tab" />
    </div>
  </div>
</template>

<script>
import UserFilesList from "./UserFilesList.vue";
export default {
  components: { UserFilesList },
  props: ["locale", "user", "profile"],
  data: function() {
    return {
      tab: "all",
    };
  },
  methods: {
    goTab: function(tab) {
      this.tab = tab;
      this.$refs.myFiles.getFiles(1, tab);
    },
  },
  mounted: function() {
    this.$refs.myFiles.getFiles(1, this.tab);
  },
};
</script>

<style lang="scss" scoped>
.user-files {
  position: relative;
  &-header {
    @include Flex(inherit, center, center);
    padding: $mpadding 0;
    margin: 0 $mpadding 0 $mpadding;
    border-bottom: solid 1px $alto;
    .button {
      @include Flex(inherit, center, center);
      padding: $mpadding;
      width: 33%;
      cursor: pointer;
      border-radius: $mradius;
      user-select: none;
      .icon {
        position: relative;
        margin: 0 $mpadding/1.5 0 0;
        top: 1px;
      }
      &.isActive {
        background-color: $alto;
        font-weight: bold;
      }
    }
  }
}
</style>
