<template>
  <Collapse ref="Subscriptions" icon="mf_cog" :title="`<strong>${$locale['social_networks']}</strong>`">
    <div class="block-input">
      <InputLabel label="Instagram" />
      <input type="url" name="instagram" :value="user.instagram" placeholder="Instagram URL" />
    </div>
    <div class="block-input">
      <InputLabel label="Twitter" />
      <input type="url" name="twitter" :value="user.twitter" placeholder="Twitter URL" />
    </div>
    <div class="block-input">
      <InputLabel label="TikTok" />
      <input type="url" name="tiktok" :value="user.tiktok" placeholder="TikTok URL" />
    </div>
    <div class="block-input">
      <InputLabel label="Facebook" />
      <input type="url" name="facebook" :value="user.facebook" placeholder="Facebook URL" />
    </div>
    <Spacer num="1" />
  </Collapse>
</template>

<script>
import Collapse from "../widgets/Collapse.vue";
export default {
  components: { Collapse },
  props: ["user"],
};
</script>
