<template>
  <div dcolor :class="`list-loading ${loading ? 'onloading' : ''}`"><iconic v-if="loading" name="gspinner" /></div>
</template>

<script>
export default {
  props: ["loading"],
};
</script>

<style lang="scss" scoped>
.list-loading {
  @include Flex(inherit, center, center);
  transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  font-size: 2em;
  height: 0;
  overflow: hidden;
  &.onloading {
    height: $mpadding * 2 + 32px;
  }
}
</style>
