<template>
  <div class="input-prefix InputPrefix">
    <input :type="type" v-model="val_txt" @keyup="prefixed" />
    <input type="hidden" :name="name" :value="val_real" />
  </div>
</template>

<script>
export default {
  props: ["name", "type", "value", "placeholder", "prefix", "dir", "decimals", "limit"],
  data: function() {
    return {
      val_txt: `${this.dir === "rtl" ? `${this.value ?? ""} ${this.prefix}` : `${this.prefix} ${this.value ?? ""}`}`,
      val_real: this.value,
    };
  },
  methods: {
    prefixed: function($event) {
      let currentValue = $event.target.value;
      if (this.decimals === true) {
        currentValue = currentValue?.replace(/[^\d.]/g, "");
      } else {
        currentValue = currentValue?.replace(/\D/g, "");
      }
      currentValue = currentValue > this.limit ? this.limit : currentValue;
      this.val_real = currentValue;
      this.val_txt = `${this.dir === "rtl" ? `${currentValue ?? ""} ${this.prefix}` : `${this.prefix} ${currentValue ?? ""}`}`;
      this.$emit("input", currentValue);
    },
  },
};
</script>
