<template>
  <div class="callling-code-select" v-if="$locale">
    <ul class="country-list">
      <li class="country-list-item" v-for="(country, index) in countries" :key="`c-${index}`" @click="setCountry(country)">
        <span class="country-list-item-flag" :style="`--flag:url(${country.flag})`"></span>
        <span class="country-list-item-name">{{ country.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      countries: null,
      allCountries: null,
    };
  },
  methods: {
    setCountry: function(country) {
      this.$emit("code", country);
    },
    getCountries: async function() {
      const countries = require(`../../assets/util/restcountries.all.json`);
      this.countries = countries;
      this.allCountries = countries;
    },
  },
  mounted: function() {
    this.getCountries();
  },
};
</script>

<style lang="scss" scoped>
.callling-code-select {
  .country-list {
    &-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: $mpadding/2 $mpadding/2;
      &:hover {
        background-color: #e9e9e9;
        font-weight: bold;
      }
      &-flag {
        @include Flex(inherit, center, center);
        width: $mpadding * 2;
        height: $mpadding * 2;
        min-width: $mpadding * 2;
        background-image: var(--flag);
        background-position: center;
        background-size: cover;
        border-radius: 50%;
      }
      &-name {
        margin: 0 0 0 $mpadding;
      }
    }
  }
}
</style>
