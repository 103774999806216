<template>
  <Collapse class="CreatorUserEdit" icon="mf_cog" :title="`<strong>${$locale['creator']}</strong>`">
    <Spacer num="1" />
    <LargeButton icon="play" line="false" :label="$locale['video_cover']" :desc="$locale['video_cover_desc']" @click="videoCover" />
    <Spacer num="1" />
  </Collapse>
</template>

<script>
export default {
  props: ["user"],
  methods: {
    videoCover: function() {
      this.view({
        title: this.$locale["video_cover"],
        class: "menu bottom",
        animation: "bottomIn",
        component: () => import("./CreatorVideoCoverEdit.vue"),
      });
    },
  },
};
</script>

<style lang="scss">
.CreatorUserEdit {
  .LargeButton {
    border: solid 1px $alto;
    border-radius: $mpadding/2;
  }
}
</style>
