<template>
  <div class="user-profile-cover UserCoverPreview" :style="styleCover">
    <label class="cover-input-area" :data-current-url="user.cover">
      <span class="overblur" centered></span>
      <img class="media" :src="user.cover" />
      <input type="file" hide hidden name="cover" @change="readURL($event)" for=".cover-input-area" accept="image/png, image/jpeg" />
      <span class="cameraHandler" centered absolute>
        <a class="camera-figure"><iconic name="mf_camera" /></a>
      </span>
      <a class="ImageAdjustButton" @click.prevent="ImageAdjust({ src: user.cover, type: 'cover', ...coverStyle, onchange })">
        <Icon name="ellipsis-h" />
      </a>
    </label>
    <label class="avatar-input-area" :style="`--userAvatar: url(${user.picture})`">
      <input type="file" hide hidden name="avatar" @change="readURL($event)" for=".user-profile-avatar" accept="image/png, image/jpeg" />
      <UserAvatar class="user-profile-avatar" :user="user" size="76px" :data-current-url="user.picture">
        <a class="ImageAdjustButton" @click.prevent="ImageAdjust({ src: user.picture, type: 'picture', ...pictureStyle, onchange })">
          <Icon name="ellipsis-h" />
        </a>
        <a class="camera-figure"><iconic name="mf_camera" /></a>
        <span class="flag" :style="`--flag:url(${user.country_flag})`"></span>
      </UserAvatar>
    </label>
  </div>
</template>

<script>
export default {
  props: ["user", "readURL", "onchange"],
  computed: {
    coverStyle: function() {
      return (this.user.style && this.user.style.cover) || {};
    },
    pictureStyle: function() {
      return (this.user.style && this.user.style.picture) || {};
    },
    moved: function() {
      let [x, y, cWidth, cHeight] = [this.coverStyle.x, this.coverStyle.y, 510, 429];

      x = (x * 100) / cWidth;
      y = (y * 100) / cHeight;

      return { x, y };
    },
    styleCover: function(style) {
      style = `--picture:url(${this.user.cover});`;
      style += `--zoom:${Go.onlyNum(this.coverStyle.zoom) || "100"}%;`;
      style += `--x:${this.moved.x || "0"}%;`;
      style += `--y:${this.moved.y || "0"}%;`;
      return style;
    },
  },
};
</script>

<style lang="scss">
.UserCoverPreview {
  .cover-input-area {
    flex-direction: column;
  }

  .overblur {
    background-image: var(--picture);
    background-position: center;
    filter: blur(20px);
    background-size: 200%;
    z-index: 0;
    width: 150%;
    height: 150%;
    position: absolute;
  }

  .media {
    position: relative;
    z-index: 1;
    transform: translate(var(--x), var(--y));
    width: calc(var(--zoom));
    height: auto;
    inset: 0px;
    max-width: 200%;
  }

  .cameraHandler {
    z-index: 2;
    cursor: pointer;
  }
}
</style>
