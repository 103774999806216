<template>
  <label class="toggle-light">
    <input class="toggle-checkbox" type="checkbox" :checked="val === values['on'] ? true : false" @change="toggle($event)" />
    <div class="toggle-switch"></div>
    <span class="toggle-label" v-if="label">{{ label }}</span>
    <input type="hidden" :name="name" :value="val" />
  </label>
</template>

<script>
export default {
  props: {
    label: String,
    name: String,
    value: String,
    values: {
      type: Object,
      default: () => {
        return {
          on: "on",
          off: "off",
        };
      },
    },
  },
  data: function() {
    return {
      val: this.value || this.values["off"],
    };
  },
  methods: {
    toggle: function($event) {
      const checked = $event.target.checked;
      this.val = checked ? this.values["on"] : this.values["off"];
      this.$emit("change", this.val);
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-light {
  cursor: pointer;
  display: inline-block;
  .toggle-switch {
    display: inline-block;
    background: #ccc;
    border-radius: 16px;
    width: 44px;
    height: 20px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
    &:before,
    &:after {
      content: "";
    }
    &:before {
      display: block;
      background: linear-gradient(to bottom, #fff 0%, #eee 100%);
      border-radius: 50%;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
      width: 24px;
      height: 24px;
      position: absolute;
      top: -2px;
      left: 0px;
      transition: left 0.25s;
    }
  }
  .toggle-checkbox:checked + .toggle-switch {
    background: $primary_color_saturate;
    &:before {
      left: 20px;
      background: $primary_color;
    }
  }
  .toggle-checkbox {
    position: absolute;
    visibility: hidden;
  }
  .toggle-label {
    margin-left: 5px;
    position: relative;
    top: 2px;
  }
}
</style>
