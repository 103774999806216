<template>
  <Collapse ref="Subscriptions" icon="mf_cog" :title="`<strong>${$locale['account_info']}</strong>`">
    <div class="block-input">
      <InputLabel :label="$locale['preferred_language']" />
      <ToggleValues
        name="lang"
        :value="user.lang"
        :values="[
          { value: 'en', label: 'ENGLISH' },
          { value: 'es', label: 'ESPAÑOL' },
        ]"
      />
    </div>
    <Spacer num="1" />
  </Collapse>
</template>

<script>
export default {
  props: ["user"],
};
</script>
