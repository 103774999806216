<template>
  <div :class="`collapse ${cstate} ${opened ? 'opened' : ''}`" :id="`collapse-${collapseID}`">
    <div class="collapse-header" @click="toggle" v-if="header !== 'hidden'">
      <div class="collapse-header-left">
        <iconic :name="icon" />
        <p class="title" v-html="title || label"></p>
      </div>
      <div class="collapse-header-right">
        <iconic class="right-icon" name="mf_chevron_down" />
      </div>
    </div>
    <div class="collapse-content">
      <slot v-if="isShowSlot"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["header", "icon", "title", "settings", "label"],
  data: function() {
    return {
      cstate: null,
      collapseID: this.$global.fastID(),
      timing: null,
      timeIn: 250,
      timeOut: 20,
      mySettings: { ...this.settings },
      opened: false,
      isShowSlot: false,
    };
  },
  methods: {
    elements: function(el) {
      const elmns = {
        collapse: document.querySelector(`#collapse-${this.collapseID}`),
        content: document.querySelector(`#collapse-${this.collapseID} .collapse-content`),
        custom: document.querySelector(el),
      };
      return elmns[el] || elmns["custom"];
    },
    open: async function(ref) {
      clearTimeout(this.timing);
      this.cstate = "open";
      await this.renderSlot();
      const [collapse, content] = [this.elements("collapse"), this.elements("content")];
      const contentHeight = content && content.scrollHeight;
      content.style.height = contentHeight + "px";
      this.timing = setTimeout(() => {
        content.style.height = "auto";
        this.afterOpen();
        this.opened = true;
      }, this.timeIn);
      if (this.cstate) {
        this.$emit(this.cstate, ref);
      }
    },
    close: async function(ref) {
      clearTimeout(this.timing);
      if (this.cstate === "open") {
        const content = this.elements("content");
        const contentHeight = content && content.scrollHeight;
        this.cstate = "close";
        content.style.height = contentHeight + "px";
        this.timing = setTimeout(() => {
          content.style.height = "0px";
          this.opened = false;
          this.renderSlot();
        }, this.timeOut);
      }
      if (this.cstate) {
        this.$emit(this.cstate, ref);
      }
    },
    afterOpen: function() {
      this.inView = this.mySettings && this.mySettings.scrollIntoView;
      if (this.inView) {
        this.scrollIntoView(this.elements("collapse"));
      }
    },
    toggle: function(ref) {
      if (this.mySettings?.disabled) {
        return;
      }
      const collapse = this.elements("collapse");
      if (this.cstate === "open") {
        this.close(ref);
      } else if (collapse) {
        this.open(ref);
      }
    },
    renderSlot: function() {
      return new Promise((resolve) => {
        if (this.mySettings?.recycle && this.cstate === "open") {
          this.isShowSlot = true;
        }
        if (this.mySettings?.recycle && this.cstate !== "open") {
          this.isShowSlot = false;
        }
        if (!this.mySettings?.recycle) {
          this.isShowSlot = true;
        }
        setTimeout(() => {
          resolve();
        }, this.timeOut / 2);
      });
    },
  },
  mounted: async function() {
    await this.renderSlot();
    if (this.mySettings?.opened) {
      this.open();
    }
  },
};
</script>

<style lang="scss" scoped>
.collapse {
  &-header {
    @include Flex(inherit, space-between, center);
    user-select: none;
    cursor: pointer;
    .iconic {
      font-size: 150%;
      color: $sec_color;
    }
    &-left,
    &-right {
      @include Flex(inherit, center, center);
    }
    &-left {
      .iconic {
        @include Flex(inherit, center, center);
        margin: 0 $mpadding/2 0 0;
      }
    }
  }
  &-content {
    overflow: hidden;
    height: 0;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
    > * {
      transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
      opacity: 0;
      transform: translateY(-100%);
      pointer-events: none;
    }
  }
  &.open {
    .right-icon {
      transform: rotate(180deg);
    }
    .collapse-content {
      > * {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  &.open.opened {
    .collapse-content {
      > * {
        pointer-events: all;
      }
    }
  }
}
</style>
