<template>
  <div class="user-files-content">
    <div class="user-files-list">
      <ListLoading :loading="loading" />
      <UserFilesCard v-for="(post, index) in posts" :key="`post-${index}`" :post="post" :tab="tab" />
    </div>
    <ViewMoreBtn :morepage="morepage" :loading="false" @click="$router.push('/favorites?filter=all&mode=thumbs')" />
  </div>
</template>

<script>
import UserFilesCard from "./UserFilesListCard.vue";
import ListLoading from "../widgets/ListLoading.vue";
import ViewMoreBtn from "../widgets/ViewMoreBtn.vue";
export default {
  components: { UserFilesCard, ListLoading, ViewMoreBtn },
  props: ["locale", "user", "profile"],
  data: function() {
    return {
      loading: true,
      posts: null,
      tab: "all",
      items: 12,
      morepage: true,
    };
  },
  methods: {
    getFiles: async function(page, tab) {
      this.loading = true;
      this.tab = tab;

      page = page >= 1 ? page : 1;
      const _host = `${process.env.VUE_APP_API_HOST}user/saved?limit=${this.items}`;
      const response = await this.$api.get(`${_host}`);

      if (page > 1) {
        this.posts.push(...response.data.posts);
      } else {
        this.posts = response.data.posts;
      }

      this.morepage = response.data?.posts?.length >= this.items;

      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss">
.user-files-list {
  display: flow-root;
  position: relative;
  padding: $mpadding/2 $mpadding/2 $mpadding $mpadding/2;
}
</style>
