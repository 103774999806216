<template>
  <div class="main-view-header main-header">
    <div class="view-header main">
      <div class="view-header-left">
        <a class="view-header-button" @click="goBack"><iconic :name="icon" /></a>
        <div>{{ title }}</div>
      </div>
      <div class="view-header-center">
        <slot name="center"></slot>
      </div>
      <div class="view-header-right">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "icon"],
  methods: {
    goBack: function() {
      this.$emit("back");
    },
  },
};
</script>

<style lang="scss" scoped>
.main-view-header {
  background: $header_bg;
  .view-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: auto;
    &-left,
    &-center,
    &-right {
      display: flex;
      align-items: center;
    }
    &-center {
      flex: auto;
    }
    &-button {
      padding: $mpadding;
      cursor: pointer;
      @include Flex(inherit, center, center);
      .iconic {
        font-size: 130%;
      }
      &:active {
        opacity: 0.7;
      }
    }
  }
}
</style>
