<template>
  <div class="user-profile UserProfileEdit" v-if="$user && $locale">
    <UserProfileEdit />
  </div>
</template>

<script>
import HamburgerBtn from "../home/HamburgerBtn.vue";
import UserCreditsBtn from "./UserCreditsBtn.vue";
import ViewHeader from "../view/ViewHeader.vue";
import UserFiles from "./UserFiles.vue";
import UserNetworksWidget from "../widgets/UserNetworksWidget.vue";
import UserProfileEdit from "./UserProfileEdit.vue";
export default {
  components: { ViewHeader, HamburgerBtn, UserCreditsBtn, UserFiles, UserNetworksWidget, UserProfileEdit },
  methods: {
    getUserUrl: function() {
      const url = `${location.protocol}//${location.host}/${this.$user.user}`;
      return url;
    },
    getData: async function() {
      await this.$store.dispatch("getProfile");
    },
  },
  mounted: function() {
    this.getData();
  },
  watch: {
    "$store.state.user": function() {
      this.getData();
    },
  },
};
</script>

<style lang="scss">
$avatar_size: 72px;
.user-profile {
  &-cover {
    @include BgImage();
    position: relative;
    min-height: 340px;
    background-image: var(--userCover);
    border-radius: 0 0 $mpadding $mpadding;
    .cover-info {
      border-radius: 0 0 $mpadding $mpadding;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      background: $lock_background;
      color: #fff;
      &-button {
        display: inline-block;
        padding: $mpadding/1.2 $mpadding;
        font-size: 150%;
        .iconic {
          display: flex;
          align-items: center;
        }
      }
      .left {
        display: flex;
        .user-picture {
          width: $avatar_size;
          height: $avatar_size;
          position: absolute;
          left: $mpadding * 1.5;
          bottom: -$mpadding;
          .picture {
            @include Flex(inherit, center, center);
            @include BgImage();
            width: $avatar_size;
            height: $avatar_size;
            border-radius: 50%;
            font-size: 180%;
            color: #fff;
            border: solid 2px #fff;
            background-image: var(--userPicture);
            &.src {
              color: rgba(255, 255, 255, 0);
            }
          }
          .flag {
            @include avatarFlag();
          }
        }
        .interact-info {
          margin: 0 0 0 $avatar_size + $mpadding * 2.5;
          .title {
            font-weight: bold;
          }
          .subtitle {
            font-size: 80%;
          }
          .title,
          .subtitle {
            .num {
              min-width: $mpadding;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  &-card {
    max-width: 100vw;
    margin: -$mpadding 0 0 0;
    padding: $mpadding * 3 $mpadding * 1.5 $mpadding $mpadding * 1.5;
    box-shadow: 2px 2px 6px rgba(68, 68, 68, 0.3);
    border-radius: 0px 0px $mpadding $mpadding;
    background: var(--card-bg);
    .name {
      font-weight: bold;
    }
    .email {
      font-size: 80%;
    }
    .desc {
      font-size: 80%;
      margin: $mpadding 0 $mpadding 0;
    }
    .item {
      margin: $mpadding/2 0 0 0;
      align-items: center;
      .icon {
        font-size: 28px;
        color: $sec_color;
        .iconic {
          @include Flex(inherit, center, center);
        }
      }
    }
    .data-user {
      position: relative;
      display: flex;
      justify-content: space-between;
      &-social {
        padding: 0 0 0 $mpadding/2;
        [class*="primary-"] {
          &:not(:last-child) {
            margin: 0 $mpadding/2 0 0;
          }
        }
      }
    }
  }
  @media (min-width: $tablet_width) {
    &-content {
      max-width: $pocket_width;
      margin: $mpadding auto;
    }
    &-cover {
      border-radius: $mpadding;
    }
    &-card {
      .data-user {
        &-social {
          [class*="primary-"] {
            &:not(:last-child) {
              margin: 0 $mpadding 0 0;
            }
          }
        }
      }
    }
  }
}
</style>
