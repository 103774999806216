<template>
  <Collapse class="UserAccountSettings" icon="mf_cog" :title="`<strong>${$locale['account_settings']}</strong>`">
    <Spacer num="1" />
    <LargeButton icon="trash" line="total" :label="$locale['delete_account']" @click="deleteAccount" dradius-mid />
    <Spacer num="1" />
  </Collapse>
</template>

<script>
const Edit = Go.vue;
export default Edit;

Edit.data = function() {
  return {};
};

Edit.methods.deleteAccount = function() {
  this.view({
    title: this.$locale["delete_account"],
    class: "menu bottom",
    animation: "bottomIn",
    component: () => import("./UserAccountDelete.vue"),
  });
};
</script>
