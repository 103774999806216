<template>
  <div class="file-card">
    <div v-for="(media, idxmedia) in post.media" :key="`media-${idxmedia}`" :class="`media-file`" :style="`--media:url(${media.thumb})`">
      <figure :class="`picture`" v-if="$global.mediaType(media.type) === 'image' && (tab === 'all' || tab === 'photos')"></figure>
      <figure :class="`picture`" v-if="$global.mediaType(media.type) === 'video' && (tab === 'all' || tab === 'videos')" video-cover></figure>
    </div>
  </div>
</template>

<script>
export default {
  props: ["tab", "post", "locale", "user", "profile"],
  data: function() {
    return {
      media: this.tab.media,
    };
  },
  methods: {
    //
  },
};
</script>

<style lang="scss" scoped>
.file-card {
  position: relative;
  .media-file {
    width: 50%;
    float: left;
    img {
      max-width: 100%;
    }
    .picture {
      margin: $mpadding/2;
      height: 156px;
      overflow: hidden;
      border-radius: $mradius;
      background-color: $dusty_gray;
      background-image: var(--media);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  @media (min-width: $tablet_width) {
    .media-file {
      width: 33%;
    }
  }
}
</style>
